import Vue from 'vue'
export default {
  namespaced: true,

  state() {
    return {
      members_loading: false,
      members: [],
      baords_loading: false,
      boards: [],
      data_loading: false,
      data: [],
      units_loading: false,
      units: [],
      teams_loading: false,
      teams: [],
      time_loading: false,
      time: [],
      poker_loading: false,
      teams_chart_type: 'bar',
      members_chart_type: 'bar'
    }
  },

  actions: {
    async initData (ctx, dates = []) {
      await Promise.all([
        ctx.dispatch('getMembers', dates),
        ctx.dispatch('getBoards'),
        ctx.dispatch('getUnits'),
        ctx.dispatch('getTeams'),
        ctx.dispatch('getTime')
      ])
    },
    async getMembers (ctx, dates = []) {
      const toast = Vue.toasted.show('Loading members...', {
        duration: null,
        action: [
          {
            text : 'OK',
            onClick : (e, toastObject) => {
              toastObject.goAway(100)
            }
          }
        ]
      })
      ctx.commit('SET_MEMBERS_LOADING', true)
      const { data } = await Vue.axios.get('/members', {
        params: {
          dates
        }
      })
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_MEMBERS_LOADING', false)
        toast.goAway(100)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_MEMBERS', data)
      }
    },
    async getBoards (ctx) {
      const toast = Vue.toasted.show('Loading boards...', {
        duration: null,
        action: [
          {
            text : 'OK',
            onClick : (e, toastObject) => {
              toastObject.goAway(100)
            }
          }
        ]
      })
      ctx.commit('SET_BOARDS_LOADING', true)
      const { data } = await Vue.axios.get('/boards')
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_BOARDS_LOADING', false)
        toast.goAway(100)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_BOARDS', data)
      }
    },
    async getUnits (ctx) {
      const toast = Vue.toasted.show('Loading units...', {
        duration: null,
        action: [
          {
            text : 'OK',
            onClick : (e, toastObject) => {
              toastObject.goAway(100)
            }
          }
        ]
      })
      ctx.commit('SET_UNITS_LOADING', true)
      const { data } = await Vue.axios.get('/units')
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_UNITS_LOADING', false)
        toast.goAway(100)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_UNITS', data)
      }
    },
    async getTeams (ctx) {
      const toast = Vue.toasted.show('Loading teams...', {
        duration: null,
        action: [
          {
            text : 'OK',
            onClick : (e, toastObject) => {
              toastObject.goAway(100)
            }
          }
        ]
      })
      ctx.commit('SET_TEAMS_LOADING', true)
      const { data } = await Vue.axios.get('/teams')
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_TEAMS_LOADING', false)
        toast.goAway(100)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_TEAMS', data)
      }
    },
    async getTime (ctx) {
      const toast = Vue.toasted.show('Loading time...', {
        duration: null,
        action: [
          {
            text : 'OK',
            onClick : (e, toastObject) => {
              toastObject.goAway(100)
            }
          }
        ]
      })
      ctx.commit('SET_TIME_LOADING', true)
      const { data } = await Vue.axios.get('/time')
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_TIME_LOADING', false)
        toast.goAway(100)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_TIME', data)
        const currentSprint = data.find(period => period.type === 'sprint' && period.current)

        if (currentSprint) {
          ctx.commit('filters/SET_CURRENT_PERIOD', currentSprint, { root: true })
        }
      }
    },
    async syncUpData (ctx, dates = []) {
      ctx.commit('SET_DATA_LOADING', true)
      const { data } = await Vue.axios.get('/cards', {
        params: {
          dates
        }
      })
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        ctx.commit('SET_DATA_LOADING', false)
      })

      if (data.err) {
        console.error(data.message)
      } else {
        ctx.commit('SET_DATA', data)
      }
    },
    async getPokerPlanning ({ state, commit, rootState }) {
      const { members, teams, units } = rootState.filters
      let cardsIds = []

      cardsIds = state.data.reduce((cardsIds, card) => {
        if (cardsIds.includes(card.id) || card.pokerPlanningFlag) {
          return cardsIds
        }

        if (members.some(member => card.idMembers.includes(member.id))) {
          cardsIds.push(card.id)
        } else if (teams.some(team => team.members.some(member => card.idMembers.includes(member.id)))) {
          cardsIds.push(card.id)
        } else if (units.some(unit => card.idLabels.includes(unit.label && unit.label.id))) {
          cardsIds.push(card.id)
        }

        return cardsIds
      }, [])

      commit('SET_POKER_LOADING', true)
      const { data } = await Vue.axios.post('/cards/poker', cardsIds)
      .catch(error => ({ data: { err: true, message: error.response.data } }))
      .finally(() => {
        commit('SET_POKER_LOADING', false)
      })

      commit('SET_POKER_INTO_CARDS', data)
    }
  },

  mutations: {
    SET_MEMBERS_LOADING (state, data) {
      state.members_loading = data
    },
    SET_MEMBERS (state, data) {
      state.members = data
    },
    UPDATE_MEMBER (state, data) {
      const index = state.members.findIndex(member => member.id === data.id)
      state.members.splice(index, 1, data)
    },
    SET_BOARDS_LOADING (state, data) {
      state.boards_loading = data
    },
    SET_BOARDS (state, data) {
      state.boards = data
    },
    SET_DATA_LOADING (state, data) {
      state.data_loading = data
    },
    SET_DATA (state, data) {
      state.data = data
    },
    SET_UNITS_LOADING (state, data) {
      state.units_loading = data
    },
    SET_UNITS (state, data) {
      state.units = data
    },
    TOGGLE_UNIT_DIALOG (state, index) {
      state.units.splice(index, 1, { ...state.units[index], dialog: !state.units[index].dialog })
    },
    SET_TEAMS_LOADING (state, data) {
      state.teams_loading = data
    },
    SET_TEAMS (state, data) {
      state.teams = data
    },
    TOGGLE_TEAM_DIALOG (state, index) {
      state.teams.splice(index, 1, { ...state.teams[index], dialog: !state.teams[index].dialog })
    },
    SET_TIME_LOADING (state, data) {
      state.time_loading = data
    },
    SET_TIME (state, data) {
      state.time = data
    },
    TOGGLE_TIME_DIALOG (state, index) {
      state.time.splice(index, 1, { ...state.time[index], dialog: !state.time[index].dialog })
    },
    SET_POKER_LOADING (state, data) {
      state.poker_loading = data
    },
    SET_POKER_INTO_CARDS (state, data) {
      state.data = [...state.data].map(card => {
        if (data[card.id]) {
          card = { ...card, ...data[card.id] }
        }
        return card
      })
    },
    SET_TEAMS_CHART_TYPE (state, data) {
      state.teams_chart_type = data
    },
    SET_MEMBERS_CHART_TYPE (state, data) {
      state.members_chart_type = data
    }
  },

  getters: {
    openStepper (state) {
      return (state.members_loading === false && state.members.length === 0) ||
        (state.boards_loading === false && state.boards.length === 0)
    },
    members (state) {
      return state.members.filter(member =>
        member.hidden == false
      )
    },
    filteredMembers (state, getters, rootState) {
      return rootState.filters.members.length
        ? rootState.filters.members
        : getters.members.filter(member =>
          member.hidden == false
            && (rootState.filters.teams.length
              ? rootState.filters.teams.some(team => team.members.some(tm => tm.id === member.id))
              : true
            )
        )
    },
    filteredTeams (state, getters, rootState) {
      return rootState.filters.teams.length
        ? rootState.filters.teams
        : state.teams
    },
    filteredUnits (state, getters, rootState) {
      return rootState.filters.units.length
        ? rootState.filters.units
        : state.units.reduce((units, unit) => {
          if (rootState.filters.teams.length) {
            const unitFiltered = rootState.filters.teams.some(team => team.members.some(tm => unit.members.some(um => um.id === tm.id)))
            if (unitFiltered) {
              units.push({
                ...unit,
                members: unit.members.filter(member =>
                  rootState.filters.teams.some(team => team.members.some(tm => tm.id === member.id))
                )
              })
            }
          } else {
            units.push(unit)
          }

          return units
        }, [])
    },
    filteredData (state, getters, rootState) {
      return state.data.filter(val => {
        let flag = true
        if (!val.dueDate && !val.dueComplete) {
          return false
        } else if (val.dueDate) {
          flag = flag && rootState.filters.timePeriodsByDate.reduce((result, period) => {
            const dueDate = new Date(val.dueDate)
            if (period[0] && period[1]) {
              const startDateFilters = new Date(period[0])
              const dueDateFilters = new Date(period[1])
              return result || startDateFilters.getTime() <= dueDate.getTime() && dueDate.getTime() <= dueDateFilters.getTime()
            } else if (period[0]) {
              const startDateFilters = new Date(period[0])
              const dueDateFilters = new Date(period[0])
              return result || startDateFilters.getTime() <= dueDate.getTime() && dueDate.getTime() <= dueDateFilters.getTime()
            } else if (period[1]) {
              const startDateFilters = new Date(period[1])
              const dueDateFilters = new Date(period[1])
              return result || startDateFilters.getTime() <= dueDate.getTime() && dueDate.getTime() <= dueDateFilters.getTime()
            } else {
              return result
            }
          }, false)
        } else {
          flag = false
        }

        if (rootState.filters.pokerPlanning) {
          flag = flag && val.pokerPlanningFlag
        }

        return flag
      })
    }
  }
}