import Vue from 'vue'

export default {
  namespaced: true,

  state() {
    return {}
  },

  actions: {
    fetch() {
      return Vue.auth.fetch()
    },

    refresh(data) {
      return Vue.auth.refresh(data)
    },

    login(ctx, data) {
      data = data || {}
      return new Promise((resolve, reject) => {
        Vue.auth.login({
          url: '/auth/login',
          data: data.body,
          remember: data.remember,
          staySignedIn: data.staySignedIn,
        })
        .then((res) => {
          if (data.remember) {
            Vue.auth.remember(JSON.stringify(res.data))
          }

          Vue.router.push({
            name: 'index'
          })

          return resolve(res)
        }, reject)
      })
    },

    register(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.auth.register({
          url: '/auth/register',
          headers: {
            Authorization: data
          },
          autoLogin: false,
        })
        .then(resolve, reject)
      })
    },

    logout() {
      return Vue.auth.logout()
    },
  },

  getters: {
    user() {
      return Vue.auth.user()
    }
  }
}