import Vue from 'vue'
import { ValidationObserver, extend } from 'vee-validate'
import { required, confirmed } from 'vee-validate/dist/rules'
import messages from 'vee-validate/dist/locale/en.json'

extend('required', {
  ...required,
  message: messages.required
})

extend('confirmed', {
  ...confirmed,
  message: messages.confirmed
})

Vue.component('ValidationObserver', ValidationObserver)