<template>
  <v-app>
    <header-vue />
    <v-main>
      <v-container :style="{ minHeight: '100%' }" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderVue from './components/Header.vue'

export default {
  name: 'App',
  components: {
    HeaderVue
  },
  data: () => ({}),
};
</script>