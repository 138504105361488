import Vue from 'vue'

import App from './index.vue'

import http   from './http'
import store from './store'
import router from './router'
import config from './config'
import vuetify from './plugins/vuetify'

require('./plugins/vue-apexcharts')
require('./plugins/vee-validate')
require('./plugins/vue-toasted')

Vue.config.productionTip = false

new Vue({
  el: '#app',
  http,
  store,
  router,
  config,
  vuetify,
  render: h => h(App)
})



