<template>
  <v-app-bar
    app
    flat
    dense
  >
    <v-toolbar-title class="title">Productivity tool</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      icon
      to="/admin/boards"
    >
      <v-icon>
        {{ mdiCog }}
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mdiCog } from '@mdi/js'
export default {
  data () {
    return {
      mdiCog
    }
  }
}
</script>