import Vue from 'vue'

import auth                  from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer      from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'type',
    refreshData: { enabled: false, interval: 0},
    tokenDefaultKey: 'auth_token',
    stores: ['storage', 'cookie'],
  }
})