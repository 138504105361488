export default {
  namespaced: true,

  state () {
    return {
      timePeriods: [],
      timePeriodsByDate: [],
      pokerPlanning: false,
      members: [],
      teams: [],
      units: []
    }
  },

  mutations: {
    SET_CURRENT_PERIOD (state, data) {
      state.timePeriods.splice(1, 1, data)
      state.timePeriodsByDate.splice(1, 1, [data.start, data.finish])
    },
    SET_TIME_PERIODS (state, data) {
      state.timePeriods = data
    },
    SET_TIME_PERIODS_BY_DATE (state, data) {
      state.timePeriodsByDate = data
    },
    SET_MEMBERS (state, data) {
      state.members = data
    },
    SET_TEAMS (state, data) {
      state.teams = data
    },
    SET_UNITS (state, data) {
      state.units = data
    },
    SET_POKER_PLANING (state, data) {
      state.pokerPlanning = data
    }
  },

  getters: {
    sprintsBetweenTwoDates (state, getters, rootState) {
      const dateStart = new Date(state.dateStart)
      const dateFinish = new Date(state.dateFinish)
      return rootState.data.time.filter(time => {
        const timeStart = new Date(time.start)
        const timeFinish = new Date(time.finish)
        if (time.type === 'sprint' && dateStart.getTime() <= timeStart.getTime() && dateFinish.getTime() >= timeFinish.getTime()) {
          return true
        }
        return false
      }).sort((a, b) => {
        const aTimeStart = new Date(a.start)
        const bTimeStart = new Date(b.start)

        return aTimeStart.getTime() - bTimeStart.getTime()
      })
    }
  }
}