import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#1976D2",
        background: "#2E2D33",
        'title-background': "#4b4952",
        green_light: "#b7deb0",
        trello_green: "#6fc25f",
        trello_green_dark: "#59ac44",
        trello_yellow_light: "#f6ea92",
        trello_yellow: "#f2d918",
        trello_yellow_dark: "#e7c60b",
        trello_orange_light: "#fbd19c",
        trello_orange: "#fea72f",
        trello_orange_dark: "#e79217",
        trello_red_light: "#f0b3ab",
        trello_red: "#ec6957",
        trello_red_dark: "#cf513d",
        trello_purple_light: "#dfc0eb",
        trello_purple: "#c883e2",
        trello_purple_dark: "#a86cc1",
        trello_blue_light: "#8cbed9",
        trello_blue: "#1885c4",
        trello_blue_dark: "#036aa7",
        trello_sky_light: "#90dfeb",
        trello_sky: "#18c7e2",
        trello_sky_dark: "#03aecc",
        trello_lime_light: "#b3f1d0",
        trello_lime: "#61e9a1",
        trello_lime_dark: "#4fd582",
        trello_pink_light: "#f8c2e4",
        trello_pink: "#fe84cf",
        trello_black_light: "#506079",
        trello_black: "#486271",
        trello_black_dark: "#081f42"
      },
    },
  },
  defaultAssets: {
    font: {
      family: 'Roboto'
    },
    icons: 'mdiSvg'
  },
  breakpoint: {
    mobileBreakpoint: 'sm'
  }
});
