import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(`../pages/${view}.vue`)
}

Vue.router = new VueRouter({
  hashbang: false,
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'index',
      component: loadView('index'),
      meta: {
        auth: true
      }
    },
    {
      path: '/trello',
      name: 'trello',
      component: loadView('trello-init'),
      meta: {
        auth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: loadView('login')
    },
    {
      path: '/register',
      name: 'register',
      component: loadView('register')
    },
    {
      path: '/admin',
      name: 'admin',
      component: loadView('admin/index'),
      meta: {
        auth: true
      },
      children: [
        { path: 'boards', name: 'boards', component: loadView('admin/boards') },
        { path: 'users', name: 'users', component: loadView('admin/users') },
        { path: 'members', name: 'members', component: loadView('admin/members') },
        { path: 'teams', name: 'teams', component: loadView('admin/teams') },
        { path: 'units', name: 'units', component: loadView('admin/units') },
        { path: 'time', name: 'time', component: loadView('admin/time') }
      ]
    }
  ]
})

export default Vue.router
